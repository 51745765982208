import React from 'react';
import { 
  IonButtons,
  IonNote,
  IonContent,
  IonHeader,
  IonMenu,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonMenuToggle,
  IonItem,
  IonIcon,
  IonLabel
} from '@ionic/react';
import { useLocation } from 'react-router-dom';
import { archiveOutline, archiveSharp, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import LoanList from './LoanList';
const LeftMenu = (props)=>{
  const location = useLocation();
  const appPages: AppPage[] = [
  {
    title: 'Loan',
    url: '/customer/loan_list',
    iosIcon: mailOutline,
    mdIcon: mailSharp
  },
  {
    title: 'LoanHistory',
    url: '/customer/loan_history',
    iosIcon: mailOutline,
    mdIcon: mailSharp
  },
  {
    title: 'Change Password',
    url: '/customer/password',
    iosIcon: archiveOutline,
    mdIcon: archiveSharp
  },
  {
    title: 'Logout',
    url: '/customer/logout',
    iosIcon: trashOutline,
    mdIcon: trashSharp
  }
];
console.log("path")
console.log(location.pathname)
  return (
    <>
      <IonMenu contentId="main-content" >
        <IonHeader>
          <IonToolbar>
            <IonTitle>Eeswaree finance</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding lefmenusec">
         <IonNote>Hi {localStorage.getItem('name') ? localStorage.getItem('name') : 'Customer'}</IonNote>
           {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonContent>
      </IonMenu>
    </>
  );
}
export default LeftMenu;