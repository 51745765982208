import {  createAnimation,
  IonModal,
  IonAvatar,
  IonCard,
  IonBadge,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonImg,IonCol,useIonLoading, IonGrid,useIonAlert, IonRow,IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar,IonItem,IonLabel,IonInput,IonNote,IonButton,IonList,IonSelect,IonSelectOption } from '@ionic/react';
import { login } from '../services/user';
import { useState, useEffect } from "react";
import { IonReactRouter } from '@ionic/react-router';
import Menu from './Menu';
import './Menu';
import DataTable from 'react-data-table-component';
const User: React.FC = (props)=>{
  const initialPasswordValues = {
   dl_number: '',
   password: '',
   confirm_password: ''
}
const [allPasswordValues, setPasswordValues] = useState(initialPasswordValues);
const [isOpen, setIsOpen] = useState(false);
const [presentAlert] = useIonAlert();
const [handlerMessage, setHandlerMessage] = useState('');
const [dl_number, setDLNumber] = useState('');
const [roleMessage, setRoleMessage] = useState('');
const [userdata, setUserData] = useState([]);
const [present, dismiss] = useIonLoading();
const [show, setShow] = useState(false);
const [search,setSearch]=useState('')
const[list,setList] = useState(false)
const initialValues = {
   name: '',
   mobile: '',
   location:'',
   password: '',
   confirm_password  : '',
   user_role:'',
   aadhar_no:'',
   user_loan_no:''
}
const [allValues, setAllValues] = useState(initialValues);
const [user_details,setUserDetails]  = useState([]);
const [user_list,setUserList]  = useState([]);
const [sample_data,setSample]= useState('')


const[name,setName] = useState('')
const[mobile,setMobileNo] = useState('')
const[location,setLocation] = useState('')
const[password,setPassword] = useState('')
const[confirm_password,setConfirmPassword] = useState('')
const[user_role,setUserRole] = useState('')
const[aadhar_no,setAadharNo] = useState('')
const[user_loan_no,setUserLoanno] = useState('')

const[filter_data,setFilterData]=useState([])

const handleNameChange = (e)=>{
    setName(e.target.value)
}
const handleMobileNoChange = (e)=>{
    setMobileNo(e.target.value)
}
const handleLocationChange = (e)=>{
    setLocation(e.target.value)
}
const handlePasswdChange = (e)=>{
    setPassword(e.target.value)
}
const handleConfirmPassChange = (e)=>{
    setConfirmPassword(e.target.value)
}
const handleUserRoleChange = (e)=>{
    setUserRole(e.target.value)
}
const handleAadharNoChange = (e)=>{
    setAadharNo(e.target.value)
}
const handleLoanNoChange = (e)=>{
    setUserLoanno(e.target.value)
}


useEffect(() => {

  setShow(false)
    fetch(`${props.api_url}/api/user_details`,{
      headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token'),
        "Accept":"application/json"
      }
    }).then(response => response.json())
    .then(json => {
      if(json.status == 401){
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        window.location.href="/"
      }else{
         console.log(json.data)
        setUserData(json.data)
       setUserDetails(json.data)
       setFilterData(json.data)
       
       setShow(true)
       setList(true)  
      }
    });
}, [sample_data,user_details]);


const columns=[
{
    name:"DL No",
    selector:(row)=>row.user_loan_no,
    sortable:true,
  },
  {
    name:"Name / Mobile",
    selector:(row)=><span className="passwordSec">
      <span>{row.name}</span><br/>
      <span>{row.mobile}</span>
    </span>,
    sortable:false,
  },
  {
    name:"Aadhar No",
    selector:(row)=>row.aadhar_no,
    sortable:true,
  },
  {
    name:"Password",
    selector:(row)=>row.confirm_password,
    sortable:true,
  },
   {
    name:"Role",
    cell:(row)=>row.user_role == "admin" ? <IonButton size="small" color="secondary">Admin</IonButton> : row.user_role == "super_admin" ? <IonButton size="small" color="success">Super Admin</IonButton> : <IonButton size="small" color="primary">Customer</IonButton>,
  },
  {
    name:"Action",
    cell:(row)=><span className="passwordSec">{row.user_role !='super_admin' ? <><IonButton size="small" color="danger" onClick={e => handleDelete(row.id)}>Delete</IonButton><br/></>:''}
          <IonButton size="small" onClick={e => handleViewUser(row.id,row.user_loan_no)}>Password</IonButton></span>,
  },
]


useEffect(()=>{
  console.log("search")
  console.log(search)
  if(search !="" && search !=null){
    const result=user_details.filter(data=>{
        return data.name.toLowerCase().match(search.toLowerCase()) || data.mobile.toLowerCase().match(search.toLowerCase())
        || data.aadhar_no.toLowerCase().match(search.toLowerCase()) || data.confirm_password.toLowerCase().match(search.toLowerCase()) || data.user_loan_no.toLowerCase().match(search.toLowerCase())
    })
    setFilterData(result)
  }else{
      console.log("ELSE")
      console.log(filter_data)
      setFilterData(user_details)
  }  
},[search])

const handleChange = (e) => {
   setAllValues({...allValues, [e.target.name]: e.target.value})
}
const handlePasswordChange = (e)=>{
  setPasswordValues({...allPasswordValues, [e.target.name]: e.target.value})
}
const handlePasswordClick = (e)=>{
    if(!allPasswordValues.password){
     presentAlert({
        subHeader: 'Please choose the password',
        buttons: ['OK'],
      }) 
    }else if(!allPasswordValues.confirm_password){
     presentAlert({
        subHeader: 'Please choose the confirm password',
        buttons: ['OK'],
      }) 
    }else if(allPasswordValues.confirm_password != allPasswordValues.password){
     presentAlert({
        subHeader: 'Password do not match. Please enter correct password.',
        buttons: ['OK'],
      }) 
    }else{
        present({
        message: 'Loading...',
        spinner: 'circles'
      });
      var data = {}
      data.dl_number = dl_number
      data.password = allPasswordValues.password
      data.confirm_password = allPasswordValues.confirm_password
      fetch(`${props.api_url}/api/change_password`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token'),
        "Accept":"application/json"
      }
    }).then(response => response.json())
   .then(json =>{
      dismiss({
        message: 'Loading...',
        spinner: 'circles'
      });
  console.log("json")
  console.log(json)
  if(json.status == 400){
      presentAlert({
        subHeader: json.message,
        buttons: ['OK'],
      }) 
  }else if(json.status == 401){
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    window.location.href="/"
  }
  else{
      setUserDetails(json.data)
     
      setIsOpen(false)
      presentAlert({
        subHeader: `Password updated Successfully`,
        buttons: ['OK'],
      }) 
  }
})
    }
}
const handleClick = ()=>{
  console.log(allValues)
    if(!user_loan_no){
      presentAlert({
        subHeader: 'Please enter the DL number',
        buttons: ['OK'],
      })  
    } 
    else if(!name){
     presentAlert({
        subHeader: 'Please enter the customer name',
        buttons: ['OK'],
      }) 
    }else if(!aadhar_no){
      presentAlert({
        subHeader: 'Please enter the aadhar number',
        buttons: ['OK'],
      })  
     } else if(!user_role){
     presentAlert({
        subHeader: 'Please choose the user role',
        buttons: ['OK'],
      }) 
    }else if(!mobile){
     presentAlert({
        subHeader: 'Please enter the mobile number',
        buttons: ['OK'],
      }) 
    }
    else if(!location){
     presentAlert({
        subHeader: 'Please enter the location',
        buttons: ['OK'],
      }) 
    }else if(!password){
     presentAlert({
        subHeader: 'Please enter the password',
        buttons: ['OK'],
      }) 
    }else if(!confirm_password){
     presentAlert({
        subHeader: 'Please enter the confirm password',
        buttons: ['OK'],
      }) 
    }else if(confirm_password != password){
     presentAlert({
        subHeader: 'Password do not match. Please enter correct password.',
        buttons: ['OK'],
      }) 
    }else{
        present({
        message: 'Loading...',
        spinner: 'circles'
      });
      var data = {}
      data.name = name
     // data.email = allValues.email
      data.user_loan_no  = user_loan_no
      data.aadhar_no  = aadhar_no
      data.mobile = mobile
      data.password = password
      data.location = location
      data.confirm_password = confirm_password
      data.user_role = user_role
      fetch(`${props.api_url}/api/register`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token'),
        "Accept":"application/json"
      }
    }).then(response => response.json())
   .then(json =>{
  console.log("json")
    dismiss({
        message: 'Loading...',
        spinner: 'circles'
      });
  console.log(json)
  setUserDetails(json.data)
   setSample(json.data)
   //setAllValues(...allValues, initialValues)
   setList(true)
  if(json.status == 400){
     
      presentAlert({
        subHeader: json.message,
        buttons: ['OK'],
      }) 
  }else if(json.status == 401){
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    window.location.href="/"
  }
  else{
    setName('')
setMobileNo('')
setPassword('')
setLocation('')
setConfirmPassword('')
setUserRole('')
setAadharNo('')
setUserLoanno('')

    
      presentAlert({
        subHeader: `Registration Successfully`,
        buttons: ['OK'],
      }) 
  }
})
  }
}

const handleDelete = (id)=>{
     presentAlert({
            header: 'Are you sure want to delete the customer',
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
              },
              {
                text: 'OK',
                role: 'confirm',
                handler: () => {
                    present({
        message: 'Loading...',
        spinner: 'circles'
      });
        var deleteData = {}
      deleteData.id=id

      


      fetch(`${props.api_url}/api/delete_user`, {
          method: "POST",
          body: JSON.stringify(deleteData),
          headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token'),
        "Accept":"application/json"
      }
        }).then(response => response.json())
        .then(json =>{
         dismiss({
        message: 'Loading...',
        spinner: 'circles'
      });

         if(json.status == 401){
          localStorage.removeItem('token');
          localStorage.removeItem('user_id');
          window.location.href="/"
         }else{
          setUserDetails(json.data)
           
            presentAlert({
            subHeader: `Customer deleted Successfully`,
            buttons: ['OK'],
            }) 
         }
            
        })   
                },
              },
            ],
           
          })
}
const handleViewUser = (id,dl_number)=>{
  setIsOpen(true)
  setDLNumber(dl_number)
}  
  return (
   <>
      <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Customer Registration</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="main-content">
        <IonGrid>
        <IonRow>
           <IonCol size="12" size-md="6" size-lg="4">
            <IonItem>
              <IonLabel position="floating">DL No</IonLabel>
              <IonInput value={user_loan_no} name="user_loan_no" onIonInput={handleLoanNoChange} placeholder="Enter Name"></IonInput>
            </IonItem>
          </IonCol>
          <IonCol size="12" size-md="6" size-lg="4">
            <IonItem>
              <IonLabel position="floating">Name</IonLabel>
              <IonInput value={name} name="name" onIonInput={handleNameChange} placeholder="Enter Name"></IonInput>
            </IonItem>
          </IonCol>
          <IonCol size="12" size-md="6" size-lg="4">
            <IonItem>
              <IonLabel position="floating">Aadhar No</IonLabel>
              <IonInput value={aadhar_no} name="aadhar_no" onIonInput={handleAadharNoChange} placeholder="Enter Name"></IonInput>
            </IonItem>
          </IonCol>
          <IonCol size="12" size-md="6" size-lg="4">
            <IonList>
              <IonItem>
                <IonSelect value={user_role} onIonChange={handleUserRoleChange} name="user_role" placeholder="Select User Role">
                  <IonSelectOption value="customer">Customer</IonSelectOption>
                  <IonSelectOption value="super_admin">Super Admin</IonSelectOption>
                  <IonSelectOption value="admin">Admin</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonList>
          </IonCol>
          <IonCol size="12" size-md="6" size-lg="4">
            <IonItem>
              <IonLabel position="floating">Mobile Number</IonLabel>
              <IonInput value={mobile} type="text"autocomplete="off" maxlength="10" name="mobile" onIonInput={handleMobileNoChange} placeholder="Enter Mobile Number"></IonInput>
            </IonItem>
          </IonCol>
          <IonCol size="12" size-md="6" size-lg="4">
            <IonItem>
              <IonLabel position="floating">Location</IonLabel>
              <IonInput value={location} name="location" onIonInput={handleLocationChange} placeholder="Enter Location"></IonInput>
            </IonItem>
          </IonCol>
          <IonCol size="12" size-md="6" size-lg="4">
            <IonItem>
              <IonLabel position="floating">Password</IonLabel>
              <IonInput type="password" value={password} name="password" onIonInput={handlePasswdChange} placeholder="Enter Password"></IonInput>
            </IonItem>
          </IonCol>
          <IonCol size="12" size-md="6" size-lg="4">
            <IonItem>
              <IonLabel position="floating">Confirm Password</IonLabel>
              <IonInput type="password" value={confirm_password} name="" onIonInput={handleConfirmPassChange} placeholder="Enter Confirm Password"></IonInput>
            </IonItem>
          </IonCol>
          <IonCol size="12" size-md="6" size-lg="4">
          <br/>
          <IonButton size="default" onClick={handleClick}>Submit</IonButton>
         </IonCol>
        </IonRow>
      </IonGrid>

      <IonRow>
      <IonCol size="12" size-md="6" size-lg="12">
        <DataTable 
        columns={columns}
        data={filter_data}
        pagination
        fixedHeader
        subHeader
        subHeaderComponent={
          <input type="text" placeholder="Search here"
          className="w-25 form-control"
          value={search}
          onChange={(e)=>setSearch(e.target.value)}/>
        }
        />
      
      </IonCol>
      </IonRow>



              <IonModal isOpen={isOpen}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Change Password</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setIsOpen(false)}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonCol size="12" size-md="6" size-lg="4">
            <IonCard>
            <IonCardHeader>
            <IonRow>
              <IonCol size="12" size-md="6" size-lg="4">
             <IonItem>
                <IonLabel position="floating">DL Number</IonLabel>
                <IonInput value={dl_number} type="text" readonly={true} onIonInput={handlePasswordChange} name="dl_number" placeholder="Enter DL Number"></IonInput>
              </IonItem>
            </IonCol>
            <IonCol size="12" size-md="6" size-lg="4">
              <IonItem>
                <IonLabel position="floating">Password</IonLabel>
                <IonInput type="password" onIonInput={handlePasswordChange} name="password" placeholder="Enter Password"></IonInput>
              </IonItem>
            </IonCol>
            <IonCol size="12" size-md="6" size-lg="4">
              <IonItem>
                <IonLabel position="floating">Confirm Password</IonLabel>
                <IonInput type="password" onIonInput={handlePasswordChange} name="confirm_password" placeholder="Enter Confirm Password"></IonInput>
              </IonItem>
            </IonCol>
            <IonCol size="12" size-md="6" size-lg="4">
              <IonButton size="default" onClick={handlePasswordClick}>Submit</IonButton>
            </IonCol>
            </IonRow>
            </IonCardHeader>
          <IonCardContent>
          <IonRow>
          </IonRow>
          </IonCardContent>
          </IonCard>
            </IonCol>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
      
   </>
  );
};
export default User;
