import React from 'react';
import {  createAnimation,
  IonModal,
  IonLoading,
  IonAvatar,
  IonCard,
  IonBadge,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonImg,IonCol,useIonLoading, IonGrid,useIonAlert, IonRow,IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar,IonItem,IonLabel,IonInput,IonNote,IonButton,IonList,IonSelect,IonSelectOption } from '@ionic/react';

import './Loan.css'
import { useState, useEffect,useRef  } from "react";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
import DataTable from 'react-data-table-component';
const LoanHistoryChild = (props)=>{
  const [present, dismiss] = useIonLoading();
  const [isOpen, setIsOpen] = useState(false);
  const [viewLoans, setViewLoans] = useState([]);
  const [due_amount, setDueAmount] = useState();
  const [paid_amount, setPaidAmount] = useState();
  const [balanc_amount, setBalancAmt] = useState();
  const [duration, setDuration] = useState();
  const[loan_lists,setLoanList] = useState([])
  const [loan_no,setLoanNo] = useState('');
  const [presentAlert] = useIonAlert();
  const [apiChk,setApiChk] = useState(true);
 const[loan_history,setLoanHistory] =useState([])
const[loan_history_flag,setLoanHistoryFlag]=useState(false)
 
const viewhistorycolumns=[
{
    name:"DL No",
    selector:(row)=>row.user_loan_no,
    sortable:true,
  },
  {
    name:"Name",
    selector:(row)=>row.name,
    sortable:true,
  },
  {
    name:"Loan No",
    selector:(row)=>row.loan_no,
    sortable:true,
  },
 
  {
    name:"Paid Date",
    selector:(row)=>row.loan_paid_date,
    sortable:true,
  },
  {
    name:"Paid Amount",
    selector:(row)=>row.loan_paid_amount,
    sortable:true,
  },
  {
    name:"Balance Amount",
    selector:(row)=>row.loan_balanc_amount,
    sortable:true,
  },
 
  {
    name:"Loan Status",
    cell:(row)=>row.status == "paid" ? <span className="HistorySec"><IonButton  color="success" size="small">Paid</IonButton></span> : <span className="HistorySec"><IonButton size="small">{row.status}</IonButton><br/></span>,
    sortable:false,
  },
]

const viewcolumns=[
{
    name:"DL No",
    selector:(row)=>row.user_loan_no,
    sortable:true,
  },
  {
    name:"Name",
    selector:(row)=>row.name,
    sortable:true,
  },
  {
    name:"Loan No",
    selector:(row)=>row.loan_no,
    sortable:true,
  },
  {
    name:"Category / Duration",
    selector:(row)=><span><span>{row.loan_category}</span><br/><span>{row.loan_duration}</span></span>,
    sortable:false,
  },
  {
    name:"Due Date",
    selector:(row)=>row.loan_issue_date,
    sortable:true,
  },
  {
    name:"Due Amount",
    selector:(row)=>row.due_amount,
    sortable:true,
  },
  {
    name:"Paid Amount",
    selector:(row)=>row.paid_amount,
    sortable:true,
  },
  {
    name:"Balance Amount",
    selector:(row)=>row.balance_amount,
    sortable:true,
  },
 
  {
    name:"Loan Status",
    cell:(row)=>row.status == "paid" ? <span className="HistorySec"><IonButton  color="success" size="small">Paid</IonButton><br/><IonButton color="tertiary" size="small" onClick={e => handleViewLoanHistory(row.customer_id,row.id,row.loan_no)}>View History</IonButton></span> : <span className="HistorySec"><IonButton size="small">{row.status}</IonButton><br/><IonButton onClick={e => handleViewLoanHistory(row.customer_id,row.id,row.loan_no)} color="tertiary" size="small">View History</IonButton></span>,
    sortable:false,
  },
]

const handleViewLoanHistory = (customer_id,loan_id,loan_no)=>{
  setLoanHistoryFlag(true)
          present({
        message: 'Loading...',
        spinner: 'circles'
      });
        var data = {}
        data.customer_id =customer_id
        data.loan_id = loan_id
        data.loan_no = loan_no
        fetch(`${props.api_url}/api/loan/loan_history`, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token'),
            "Accept":"application/json"
          }
        }).then(response => response.json())
        .then(json =>{
            dismiss({
              message: 'Loading...',
              spinner: 'circles'
            });
             if(json.status == 400){
              presentAlert({
                subHeader: json.message,
                buttons: ['OK'],
              }) 
            }else if(json.status == 401){
                localStorage.removeItem('token');
                localStorage.removeItem('user_id');
                window.location.href="/"
            }else{
               console.log("TEST")
               console.log(json.data)
               setLoanHistory(json.data)
            }
        })
    }

  useEffect(() => {
    var data = {}
    data.customer_id= localStorage.getItem('user_id');
    fetch(`${props.api_url}/api/loan/defaultLoan`, {
          method: "POST",
          body: JSON.stringify(data),
        headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token'),
        "Accept":"application/json"
      }
        }).then(response => response.json())
        .then(json =>{
        console.log("json")
        console.log(json)
        if(json.status == 400){
          presentAlert({
            subHeader: json.message,
            buttons: ['OK'],
          }) 
        }else if(json.status == 401){
          localStorage.removeItem('token');
          localStorage.removeItem('user_id');
          window.location.href="/"
        }
        else{
          setLoanList(json.data)
          console.log("hellooo")
          console.log(json.viewLoan)
            setApiChk(false)
          }
        })
  }, []);


  const handleSuggestion = (e,newInputValue)=>{
   if(newInputValue !=""){
    //setLoanList([])
    present({
        message: 'Loading...',
        spinner: 'circles'
      });
      var data = {}
      data.loan_no = newInputValue
      fetch(`${props.api_url}/api/loan/viewLoan`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token'),
        "Accept":"application/json"
      }
    }).then(response => response.json())
   .then(json =>{
  console.log("json")
  console.log(json)
  dismiss({
        message: 'Loading...',
        spinner: 'circles'
      });
  if(json.status == 400){
      presentAlert({
        subHeader: json.message,
        buttons: ['OK'],
      }) 
  }else if(json.status == 401){
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    window.location.href="/"
  }

  else{
    console.log("DATA")
     console.log(json.data)
     setLoanList(json.data)
  }
})  
  }
  }

  
  const handleCardClick = (loan_no,loan_id,customer_id)=>{
    setIsOpen(true)
    setViewLoans([])
    setLoanNo(loan_no)
      present({
        message: 'Loading...',
        spinner: 'circles'
      });
     var data = {}
    data.customer_id= customer_id;
    data.loan_no= loan_no;
    data.loan_id= loan_id;
    console.log('trigger use effect hook');
      fetch(`${props.api_url}/api/loan/view_loan_list`, {
          method: "POST",
          body: JSON.stringify(data),
        headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token'),
        "Accept":"application/json"
      }
        }).then(response => response.json())
        .then(json =>{
        console.log("json")
        console.log(json)
        if(json.status == 400){
          presentAlert({
            subHeader: json.message,
            buttons: ['OK'],
          }) 
        }else if(json.status ==401){
          localStorage.removeItem('token');
          localStorage.removeItem('user_id');
          window.location.href="/"
        }
        else{
          setViewLoans(json.data)
        dismiss({
        message: 'Loading...',
        spinner: 'circles'
      });
        }
        })
  }
  console.log("apiChk")
  console.log(apiChk)

  return (
    <>
    {apiChk && <IonLoading
        cssClass='my-custom-class'
        isOpen={apiChk}
        message={'Loading...'}
        spinner={'circles'}
        onDidDismiss={() => setApiChk(false)}
        duration={5000}
      /> }
    {!apiChk && 
    <IonGrid>
    <IonRow>
     <IonCol size="12" size-md="6" size-lg="4" className="autoInput">
      <Stack spacing={1} sx={{ width: 300}}>
      <Autocomplete
        id="free-solo-demo"
        freeSolo
        onChange={handleSuggestion}
        options={props.loan_list.map((option) => option.loan_no)}
        renderInput={(params) => <TextField {...params} label="Loan Search" />}
      />
      </Stack>
     </IonCol>  
    </IonRow>
      
    <IonRow>
      <IonCol size="12" size-md="6" size-lg="12">
        <DataTable 
        columns={viewcolumns}
        data={loan_lists}
        pagination
        />
      </IonCol>
    </IonRow>

    <div id="box">
        <IonModal isOpen={loan_history_flag} >
          <IonHeader>
            <IonToolbar>
            <IonTitle>Loan History</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setLoanHistoryFlag(false)}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
         
            <IonCol size="12" size-md="6" size-lg="4">
            <IonCard>
            <IonCardHeader>
              <IonRow>
                <IonCol size="12" size-md="6" size-lg="12">
                 
                     <DataTable 
        columns={viewhistorycolumns}
        data={loan_history}
        pagination
       
        /> 

                </IonCol>
              </IonRow>

            
            </IonCardHeader>
          <IonCardContent>
          <IonRow>
          </IonRow>
          </IonCardContent>
          </IonCard>
            </IonCol>
          </IonContent>
        </IonModal>
        </div>
    </IonGrid>
  }
    </>
  ); 

 
}
export default LoanHistoryChild;