import React from 'react';
import './Menu.css';
function About () {
	if(localStorage.getItem('user_role') == "customer"){
		window.location.href="/customer/loan_list";
	}else{
		window.location.href="/page/dashboard";
	}
	return(
			<>
			</>
	);
}
export default About;