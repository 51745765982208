import React from 'react';
import { 
  IonButtons,
  IonContent,
  IonHeader,
  IonMenu,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonMenuToggle,
  IonItem,
  IonIcon,
  IonLabel,
  useIonAlert,
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
import {archiveOutline, archiveSharp, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import { useState, useEffect } from "react";
import LoanList from './LoanList';
import LeftMenu from './LeftMenu';
import Password from './Password';
import { 
useIonLoading
} from '@ionic/react';
const CustomerLoanList: React.FC = (props)=>{
  const location = useLocation();
   const [present, dismiss] = useIonLoading();
  const [presentAlert] = useIonAlert();
  const [loan_details, setLoanDetails] = useState([]);
  const[loan_list,setLoanList] = useState([])

  const [due_amounts, setDueAmount] = useState('');
  const [paid_amounts, setPaidAmount] = useState('');
  const [balanc_amounts, setBalancAmt] = useState('');
  const [durations, setDuration] = useState('');
 
  useEffect(() => {
    
    var data = {}
    data.customer_id= localStorage.getItem('user_id');
 


         fetch(`${props.api_url}/api/loan/loan_list`, {
          method: "POST",
          body: JSON.stringify(data),
       headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token'),
        "Accept":"application/json"
      }
        }).then(response => response.json())
        .then(json =>{
        console.log("json")
        console.log(json)
        if(json.status == 400){
          presentAlert({
            subHeader: json.message,
            buttons: ['OK'],
          }) 
        }else if(json.status == 401){
          localStorage.removeItem('token');
          localStorage.removeItem('user_id');
          window.location.href="/"
        }else{
          setLoanList(json.data)
        }
        })
}, []);


    const appPages: AppPage[] = [
  {
    title: 'Loan',
    url: '/customer/loan_list',
    iosIcon: mailOutline,
    mdIcon: mailSharp
  },
  {
    title: 'Change Password',
    url: '/customer/password',
    iosIcon: archiveOutline,
    mdIcon: archiveSharp
  },
  {
    title: 'Logout',
    url: '/customer/logout',
    iosIcon: trashOutline,
    mdIcon: trashSharp
  }
];
console.log("path")
console.log(loan_details)


  return (
    <>
    <LeftMenu/>
      <IonPage id="main-content">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonTitle>Loan</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <LoanList due_amounts={due_amounts} paid_amounts={paid_amounts} balanc_amounts={balanc_amounts} durations={durations} loan_details={loan_details} loan_list={loan_list} api_url={props.api_url}/>
        </IonContent>
      </IonPage>
    </>
  ); 

 
}
export default CustomerLoanList;
