import React from 'react';
import './Menu.css';
import { useState, useEffect } from "react";
import { IonContent,IonGrid,IonPage,IonHeader,IonToolbar,IonTitle,IonMenuButton,IonButtons,IonCol,IonRow,IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle } from '@ionic/react';
function Dashboard (props) {
const [disburse_amount,setDisburseAmunt] = useState(0)
const [received_amount,setReceivedAmount] = useState(0)
  useEffect(() => {
    fetch(`${props.api_url}/api/loan/dashboard`,{
      headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token'),
        "Accept":"application/json"
      }
    }).then(response => response.json())
    .then(json => {
      if(json.status == 401){
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        window.location.href="/"
      }else{
       console.log(json)
       setReceivedAmount(json.loan_paid_amount)
       setDisburseAmunt(json.loan_amount)
      }
    });
}, []);

	return(
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
						<IonMenuButton />
						</IonButtons>
						<IonTitle>Dashboard</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent fullscreen className="main-content">
        		<IonGrid>
				<IonRow>
				 	<IonCol size="12" size-md="6" size-lg="4">
						<IonCard className="dashboard-first">
							<IonCardHeader>
							<IonCardTitle>Loan Disbursal</IonCardTitle>
							</IonCardHeader>
							<IonCardContent>
							<h2 className="loan_amount">&#x20b9; {disburse_amount}</h2>
							</IonCardContent>
						</IonCard>
					</IonCol>
					<IonCol size="12" size-md="6" size-lg="4">
						<IonCard className="dashboard-second">
							<IonCardHeader>
							<IonCardTitle>Loan Received Amount</IonCardTitle>
							</IonCardHeader>
							<IonCardContent>
							<h2 className="loan_amount">&#x20b9; {received_amount}</h2>
							</IonCardContent>
						</IonCard>
					</IonCol>
				</IonRow>
        		</IonGrid>
        		</IonContent>
			</IonPage>
	);
}
export default Dashboard;