import { IonCol,useIonAlert, IonGrid, IonRow,IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar,IonItem,IonLabel,IonInput,IonNote,IonButton,IonSelect, IonSelectOption,IonList,useIonLoading } from '@ionic/react';
import { useState, useEffect } from "react";
import UserTable from './UserTable';
const ChangePassword: React.FC = (props)=>{
const [presentAlert] = useIonAlert();
const [present, dismiss] = useIonLoading();
const initialValues = {
   mobile: '',
   password: '',
   confirm_password: ''
}
const [allValues, setAllValues] = useState(initialValues);
const [user_details,setUserDetails]  = useState([]);
const [userdata, setUserData] = useState([]);
const handleChange = (e) => {
   setAllValues({...allValues, [e.target.name]: e.target.value})
}
useEffect(() => {
    fetch(`${props.api_url}/api/user_details`)
    // Converting received data to JSON
    .then(response => response.json())
    .then(json => {
      if(json.status == 401){
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        window.location.href="/"
      }else{
       setUserData(json.data)
       setUserDetails(json.data)
      }
    });
}, []);

const handleClick = ()=>{
   if(!allValues.mobile){
     presentAlert({
        subHeader: 'Please choose the customer name',
        buttons: ['OK'],
      }) 
    }else if(!allValues.password){
     presentAlert({
        subHeader: 'Please choose the password',
        buttons: ['OK'],
      }) 
    }else if(!allValues.confirm_password){
     presentAlert({
        subHeader: 'Please choose the confirm password',
        buttons: ['OK'],
      }) 
    }else if(allValues.confirm_password != allValues.password){
     presentAlert({
        subHeader: 'Password do not match. Please enter correct password.',
        buttons: ['OK'],
      }) 
    }else{
      present({
        message: 'Loading...',
        spinner: 'circles'
      });
      var data = {}
      data.mobile = allValues.mobile
      data.password = allValues.password
      data.confirm_password = allValues.confirm_password
      fetch("http://localhost:8000/api/change_password", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    }).then(response => response.json())
   .then(json =>{
    dismiss({
        message: 'Loading...',
        spinner: 'circles'
    });
  console.log("json")
  console.log(json)
  if(json.status == 400){
      presentAlert({
        subHeader: json.message,
        buttons: ['OK'],
      }) 
  }else if(json.status == 401){
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    window.location.href="/"
  }else{
      setUserDetails(json.data)
      presentAlert({
        subHeader: `Password updated Successfully`,
        buttons: ['OK'],
      }) 
  }
})
    }
}
  return (
   <>
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Change Password</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonGrid>
          <IonRow>
            <IonCol size="12" size-md="6" size-lg="4">
              <IonList>
                <IonItem>
                  <IonSelect onIonChange={handleChange}  name="mobile" placeholder="Select Customer Name">
                    {userdata.map((userdetail) => (
                    <IonSelectOption key={userdetail.id} value={userdetail.mobile}>
                    {userdetail.name} / {userdetail.mobile}
                    </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
            </IonList>
            </IonCol>
            <IonCol size="12" size-md="6" size-lg="4">
              <IonItem>
                <IonLabel position="floating">Password</IonLabel>
                <IonInput type="password" onIonInput={handleChange} name="password" placeholder="Enter Password"></IonInput>
              </IonItem>
            </IonCol>
            <IonCol size="12" size-md="6" size-lg="4">
              <IonItem>
                <IonLabel position="floating">Confirm Password</IonLabel>
                <IonInput type="password" onIonInput={handleChange} name="confirm_password" placeholder="Enter Confirm Password"></IonInput>
              </IonItem>
            </IonCol>
            <IonCol size="12" size-md="6" size-lg="4">
              <IonButton size="default" onClick={handleClick}>Submit</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonRow>
        <IonCol size="12" size-md="6" size-lg="12">
          <UserTable user_details={user_details}/>
        </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
   </>
  );
};
export default ChangePassword;
