import { useIonLoading,IonCol,useIonAlert, IonGrid, IonRow,IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar,IonItem,IonLabel,IonInput,IonNote,IonButton,IonSelect, IonSelectOption,IonList } from '@ionic/react';
import { useState, useEffect } from "react";
import LeftMenu from './LeftMenu';
const Password: React.FC = (props)=>{
const [presentAlert] = useIonAlert();
const initialValues = {
   password: '',
   confirm_password: ''
}
const [allValues, setAllValues] = useState(initialValues);
const [present, dismiss] = useIonLoading();
const handleChange = (e) => {
   setAllValues({...allValues, [e.target.name]: e.target.value})
}
const handleClick = ()=>{
    var data = {}
    data.password = allValues.password
    data.confirm_password = allValues.confirm_password
    data.id =  localStorage.getItem('user_id');
    if(!allValues.password){
     presentAlert({
        subHeader: 'Please enter the password',
        buttons: ['OK'],
      }) 
    }else if(!allValues.confirm_password){
     presentAlert({
        subHeader: 'Please enter the confirm password',
        buttons: ['OK'],
      }) 
    }else if(allValues.password != allValues.confirm_password){
     presentAlert({
        subHeader: 'Password do not match please enter correct password',
        buttons: ['OK'],
      }) 
    }else{
       present({
        message: 'Loading...',
        spinner: 'circles'
      });
      fetch(`${props.api_url}/api/password`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token'),
        "Accept":"application/json"
      }
    }).then(response => response.json())
   .then(json =>{
     dismiss({
        message: 'Loading...',
        spinner: 'circles'
      });
  console.log("json")
  console.log(json)
  if(json.status == 400){
      presentAlert({
        subHeader: json.message,
        buttons: ['OK'],
      }) 
  }else if(json.status == 401){
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    window.location.href="/"
  }
  else{
      presentAlert({
        subHeader: `Password updated Successfully`,
        buttons: ['OK'],
      }) 
  }
})
    }
}
  return (
   <>
   <LeftMenu />
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Password</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonGrid>
          <IonRow>
            <IonCol size="12" size-md="6" size-lg="4">
              <IonItem>
                <IonLabel position="floating">Password</IonLabel>
                <IonInput type="password" onIonInput={handleChange} name="password" placeholder="Enter Password"></IonInput>
              </IonItem>
            </IonCol>
            <IonCol size="12" size-md="6" size-lg="4">
              <IonItem>
                <IonLabel position="floating">Confirm Password</IonLabel>
                <IonInput type="password" onIonInput={handleChange} name="confirm_password" placeholder="Enter Confirm Password"></IonInput>
              </IonItem>
            </IonCol>
            <IonCol size="12" size-md="6" size-lg="4">
              <br/>
              <IonButton size="default" onClick={handleClick}>Submit</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
   </>
  );
};
export default Password;
