import React from 'react';
import {  createAnimation,
  IonModal,
  IonLoading,
  IonAvatar,
  IonCard,
  IonBadge,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonImg,IonCol,useIonLoading, IonGrid,useIonAlert, IonRow,IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar,IonItem,IonLabel,IonInput,IonNote,IonButton,IonList,IonSelect,IonSelectOption } from '@ionic/react';

import './Loan.css'
import { useState, useEffect,useRef  } from "react";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
import DataTable from 'react-data-table-component';

const LoanList = (props)=>{
  const [present, dismiss] = useIonLoading();
  const [isOpen, setIsOpen] = useState(false);
  const [viewLoans, setViewLoans] = useState([]);
  const [due_amount, setDueAmount] = useState();
  const [paid_amount, setPaidAmount] = useState();
  const [balanc_amount, setBalancAmt] = useState();
  const [duration, setDuration] = useState();
  const[loan_lists,setLoanList] = useState([])
  const [loan_no,setLoanNo] = useState('');
  const [presentAlert] = useIonAlert();
  const [apiChk,setApiChk] = useState(true);
  useEffect(() => {
    var data = {}
    data.customer_id= localStorage.getItem('user_id');
    fetch(`${props.api_url}/api/loan/activeloan`, {
          method: "POST",
          body: JSON.stringify(data),
        headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token'),
        "Accept":"application/json"
      }
        }).then(response => response.json())
        .then(json =>{
        console.log("json")
        console.log(json)
        if(json.status == 400){
          presentAlert({
            subHeader: json.message,
            buttons: ['OK'],
          }) 
        }else if(json.status == 401){
          localStorage.removeItem('token');
          localStorage.removeItem('user_id');
          window.location.href="/"
        }
        else{
          setLoanList(json.data)
          console.log("hellooo")
          console.log(json.viewLoan)
            if(json.viewLoan.length > 0){
            setDueAmount(json.viewLoan[0].due_amount)
            setPaidAmount(json.viewLoan[0].paid_amount)
            const balanceAmounts = json.viewLoan[0].due_amount - json.viewLoan[0].paid_amount
            setBalancAmt(balanceAmounts)
            setDuration(json.viewLoan[0].loan_duration)
            }else{
              setDueAmount(0)
              setPaidAmount(0)
              setBalancAmt(0)
              setDuration(0)
            }
            setApiChk(false)
          }
        })
  }, []);


  const handleSuggestion = (e,newInputValue)=>{
   if(newInputValue !=""){
    //setLoanList([])
    present({
        message: 'Loading...',
        spinner: 'circles'
      });
      var data = {}
      data.loan_no = newInputValue
      fetch(`${props.api_url}/api/loan/searchLoan`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token'),
        "Accept":"application/json"
      }
    }).then(response => response.json())
   .then(json =>{
  console.log("json")
  console.log(json)
  dismiss({
        message: 'Loading...',
        spinner: 'circles'
      });
  if(json.status == 400){
      presentAlert({
        subHeader: json.message,
        buttons: ['OK'],
      }) 
  }else if(json.status == 401){
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    window.location.href="/"
  }

  else{
    console.log("DATA")
     console.log(json.data)
     setLoanList(json.data)
      if(json.totalLoan.length > 0){
          setDueAmount(json.totalLoan[0].due_amount)
          setPaidAmount(json.totalLoan[0].paid_amount)
          const balanceAmounts = json.totalLoan[0].due_amount - json.totalLoan[0].paid_amount
         
          setBalancAmt(balanceAmounts)
          setDuration(json.totalLoan[0].loan_duration)
    }else{
        setDueAmount(0)
        setPaidAmount(0)
        setBalancAmt(0)
        setDuration(0)
    }
  }
})  
  }
  }

  


const columns=[
{
    name:"Loan No",
    selector:(row)=>row.loan_no,
    sortable:true,
  },
  {
    name:"Paid Amount",
    selector:(row)=>row.loan_paid_amount,
    sortable:true,
  },
  {
    name:"Balance Amount",
    selector:(row)=>row.loan_balanc_amount,
    sortable:true,
  },
  
  {
    name:"Paid Date",
    selector:(row)=>row.loan_paid_date,
    sortable:true,
  },
]
  const handleCardClick = (loan_no,loan_id,customer_id)=>{
    setIsOpen(true)
    setViewLoans([])
    setLoanNo(loan_no)
      present({
        message: 'Loading...',
        spinner: 'circles'
      });
     var data = {}
    data.customer_id= customer_id;
    data.loan_no= loan_no;
    data.loan_id= loan_id;
    console.log('trigger use effect hook');
      fetch(`${props.api_url}/api/loan/view_loan_list`, {
          method: "POST",
          body: JSON.stringify(data),
        headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token'),
        "Accept":"application/json"
      }
        }).then(response => response.json())
        .then(json =>{
        console.log("json")
        console.log(json)
        if(json.status == 400){
          presentAlert({
            subHeader: json.message,
            buttons: ['OK'],
          }) 
        }else if(json.status ==401){
          localStorage.removeItem('token');
          localStorage.removeItem('user_id');
          window.location.href="/"
        }
        else{
          setViewLoans(json.data)
            $(document).ready(function () {
                if ( $.fn.DataTable.isDataTable('#loan_txn') ) {
                  $('#loan_txn').DataTable().destroy();
                }
                $('#loan_txn').DataTable({
                 "ordering":false,
                 "bLengthChange":false,
                 "bInfo": false
                });
            });
        dismiss({
        message: 'Loading...',
        spinner: 'circles'
      });
        }
        })
  }
  console.log("apiChk")
  console.log(apiChk)

  return (
    <>
    {apiChk && <IonLoading
        cssClass='my-custom-class'
        isOpen={apiChk}
        message={'Loading...'}
        spinner={'circles'}
        onDidDismiss={() => setApiChk(false)}
        duration={5000}
      /> }
    {!apiChk && 
    <IonGrid>
    <IonRow>
     <IonCol size="12" size-md="6" size-lg="4" className="autoInput">
      <Stack spacing={1} sx={{ width: 300}}>
      <Autocomplete
        id="free-solo-demo"
        freeSolo
        onChange={handleSuggestion}
        options={props.loan_list.map((option) => option.loan_no)}
        renderInput={(params) => <TextField {...params} label="Loan Search" />}
      />
      </Stack>
     </IonCol>  
    </IonRow>

    <IonRow>
      <IonCol size="12" size-md="12" size-lg="12">
      <IonCard>
        <IonCardHeader>
          <IonRow>
            <IonCol size="12" size-md="6" size-lg="3">
              <IonCardTitle>Total Loan Amount : <IonBadge >{due_amount ? Math.round(due_amount) : 0} </IonBadge></IonCardTitle>
            </IonCol>
            <IonCol size="12" size-md="6" size-lg="3">
              <IonCardTitle>Total Paid Amount : <IonBadge color="success">{paid_amount ? Math.round(paid_amount) : 0} </IonBadge></IonCardTitle>
            </IonCol>
            <IonCol size="12" size-md="6" size-lg="3">
              <IonCardTitle>Total Balance Amount : <IonBadge color="danger">{balanc_amount ? Math.round(balanc_amount) : 0} </IonBadge></IonCardTitle>
            </IonCol>
             <IonCol size="12" size-md="6" size-lg="3">
              <IonCardTitle>Duration : <IonBadge color="secondary">{duration ? duration : 0} </IonBadge></IonCardTitle>
            </IonCol>
          </IonRow>
        </IonCardHeader>
        </IonCard>
      </IonCol>
    </IonRow>

    <IonRow>
      
        
      {loan_lists.length > 0 ?

        loan_lists.map((loan) => (
        <IonCol size="12" size-md="6" size-lg="4" onClick={e=>handleCardClick(loan.loan_no,loan.id,loan.customer_id)}>
          <IonCard>
          <IonCardHeader>
          <IonCardTitle>Loan No : {loan.loan_no ? loan.loan_no : ''}</IonCardTitle>
            <IonCardTitle>Loan Category  : {loan.loan_category}</IonCardTitle>
            <IonCardTitle>Loan Due Amount  : {loan.loan_due_amount ? Math.round(loan.loan_due_amount) : 0}</IonCardTitle>
            <IonCardTitle>Loan Paid Amount  : {loan.loan_paid_amount ? Math.round(loan.loan_paid_amount) : 0}</IonCardTitle>
            <IonCardTitle>Loan Balance Amount  : {loan.loan_balanc_amount ? Math.round(loan.loan_balanc_amount) : 0}</IonCardTitle>
            <IonCardTitle>Loan Due Date  : {loan.loan_issue_date}</IonCardTitle>
            <IonCardTitle>Loan Status  : 
            {loan.status == "outstanding" ?
                <IonButton size="small" color="danger">Out Standing</IonButton>
            :loan.status == "paid" ?
                <IonButton size="small" color="success">Paid</IonButton>

            :loan.status == "partially_paid" ?
                <IonButton size="small">Partially Paid</IonButton>

            :
                <IonButton size="small" color="danger">Pending Due</IonButton>
            }
            </IonCardTitle>
            <IonRow>
            </IonRow>
          </IonCardHeader>
          </IonCard>
        </IonCol>
      ))
      :
     
          <IonCard>
            <IonCardHeader>No Loan Found</IonCardHeader>
          </IonCard>
       
    }

      <IonModal isOpen={isOpen}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Payment Transaction Details (Loan No : {loan_no})</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setIsOpen(false)}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonCol size="12" size-md="6" size-lg="4">
              <IonCard>
              <IonCardContent>
                <IonRow>
                <IonCol size="12" size-md="6" size-lg="6">
                  <DataTable 
        columns={columns}
        data={viewLoans}
        pagination
                
        />

                </IonCol>
              
                </IonRow>
              </IonCardContent>
              </IonCard>
            </IonCol>
          </IonContent>
        </IonModal>
    </IonRow>
    </IonGrid>
  }
    </>
  ); 

 
}
export default LoanList;