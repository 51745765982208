import { IonCard,IonCardTitle,IonCardHeader,IonCol,useIonLoading,useIonAlert,IonGrid, IonRow,IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar,IonItem,IonLabel,IonInput,IonNote,IonButton,IonList,IonSelect,IonSelectOption } from '@ionic/react';
import { useState, useEffect } from "react";
import LoanConfigTable from './LoanConfigTable';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import './Loan.css';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const LoanConfig: React.FC = (props)=>{
const [present, dismiss] = useIonLoading();
const [show, setShow] = useState(false);
const [loan_config, setLoanConfig] = useState([]);
const [dlnumber,setDLNumber] = useState([])
const [presentAlert] = useIonAlert();

const [dealernumber,setDealerNumber] = useState('')
const [category,setCategory] = useState('')
const [loan_amount,setLoanAmount] = useState('')
const [duration,setDuration] = useState('')
const [interest,setInterest] = useState(1)

const [loan_report,setLoanReport] = useState([])

const handleSuggestion = (e,value)=>{
 setDealerNumber(value)
}
const handleCategoryChange= (e)=>{
  setCategory(e.target.value)
}
const handleLoanChange = (e)=>{
  setLoanAmount(e.target.value)
}
const handleDurationChange = (e)=>{
  setDuration(e.target.value)
}
const handleInterestChange = (e)=>{
  setInterest(e.target.value)
}

const handleClick = ()=>{
 if (!dealernumber || dealernumber == "" || dealernumber == null) {
    presentAlert({
      subHeader: 'Please choose the DL Number',
      buttons: ['OK'],
    })
  }else if(!category){
    presentAlert({
      subHeader: 'Please choose the loan category',
      buttons: ['OK'],
    })
  }else if(!loan_amount){
    presentAlert({
      subHeader: 'Please enter the loan amount',
      buttons: ['OK'],
    })
  }
  else if(!duration){
    presentAlert({
      subHeader: 'Please enter the duration',
      buttons: ['OK'],
    })
  }else if(!interest){
     presentAlert({
      subHeader: 'Please enter the interest',
      buttons: ['OK'],
    })
  }else{
    const data = {}
    data.dealernumber = dealernumber
    data.category = category
    data.loan_amount = loan_amount
    data.duration = duration
    data.interest = interest
        present({
        message: 'Loading...',
        spinner: 'circles'
      });
      fetch(`${props.api_url}/api/loan_config/interest_report`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token'),
        "Accept":"application/json"
      }
    }).then(response => response.json())
   .then(json =>{
  console.log("json")
  console.log(json)
  dismiss({
    message: 'Loading...',
    spinner: 'circles'
  });

  if(json.status == 400){
      presentAlert({
        subHeader: json.message,
        buttons: ['OK'],
      }) 
  }else if(json.status == 401){
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    window.location.href="/"
  }

  else{
    console.log("DATA")
     console.log(json.data)
     setLoanReport(json.data)
  }
}) 

  }
}
useEffect(() => {
  setShow(false)
    console.log('trigger use effect hook');
    fetch(`${props.api_url}/api/loan_config/show`,{
      headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token'),
        "Accept":"application/json"
      }
    })
    // Converting received data to JSON
    .then(response => response.json())
    .then(json => {

        if(json.status == 401){
          localStorage.removeItem('token');
          localStorage.removeItem('user_id');
          window.location.href="/"
        }else{
           console.log(json.data)
       setLoanConfig(json.data)
       setShow(true)
        }
      
       $(document).ready(function () {
          if ( $.fn.DataTable.isDataTable('#loanConfigTable') ) {
            $('#loanConfigTable').DataTable().destroy();
          }
          $('#loanConfigTable').DataTable({
           "ordering":false,
           "bLengthChange":false,
           "bInfo": false
          });
        });
    });


    fetch(`${props.api_url}/api/user_loan`,{
      headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token'),
        "Accept":"application/json"
      }
    })
    // Converting received data to JSON
    .then(response => response.json())
    .then(json => {
      if(json.status == 401){
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        window.location.href="/"
      }else{
        console.log(json.data)
        setDLNumber(json.data)
      }
    });
}, []);



// const handleClick = ()=>{

//    if(!allValues.loan_category){
//      presentAlert({
//         subHeader: 'Please choose the loan category',
//         buttons: ['OK'],
//       }) 
//     }else if(!allValues.loan_interest){
//      presentAlert({
//         subHeader: 'Please enter the interest rate',
//         buttons: ['OK'],
//       }) 
//     }else{

//       present({
//         message: 'Loading...',
//         spinner: 'circles'
//       });
//       var data = {}
//       data.loan_category = allValues.loan_category
//       data.loan_interest = allValues.loan_interest

//       fetch(`${props.api_url}/api/loan_config/create`, {
//       method: "POST",
//       body: JSON.stringify(data),
//       headers: {
//         "Content-type": "application/json; charset=UTF-8"
//       }
//     }).then(response => response.json())
//    .then(json =>{
//      dismiss({
//         message: 'Loading...',
//         spinner: 'circles'
//     });
//   console.log("json")
//   console.log(json)
//   if(json.status == 400){
//       presentAlert({
//         subHeader: json.message,
//         buttons: ['OK'],
//       }) 
//   }else{
//      setLoanConfig(json.data)
//       presentAlert({
//         subHeader: `Loan interest added Successfully`,
//         buttons: ['OK'],
//       }) 
//   }
// })
//     }
// }

const handlePrint = ()=>{


  $("#printContent").printThis({
     debug: false
  })
}
  return (
   <>
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Loan interest</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="main-content">
        <IonGrid>
          <IonRow>
          <IonCol size="12" size-md="6" size-lg="4">
            <Stack spacing={1} sx={{ width: 300 }}>
            <Autocomplete
            id="free-solo-demo"
            freeSolo
            onChange={handleSuggestion}
            options={dlnumber.map((option) => option.user_loan_no)}
            renderInput={(params) => <TextField {...params} label="DL Number" />}
            />
            </Stack>
          </IonCol>
          <IonCol size="12" size-md="6" size-lg="4">
            <IonList>
                <IonItem>
                  <IonSelect value={category} onIonChange={handleCategoryChange} name="loan_category" placeholder="Select Loan Category">
                    <IonSelectOption value="daily">Daily</IonSelectOption>
                    <IonSelectOption value="monthly">Monthly</IonSelectOption>
                    <IonSelectOption value="weekly">Weekly</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonList>
          </IonCol>
          <IonCol size="12" size-md="6" size-lg="4">
             <IonItem>
                <IonLabel position="floating">Loan Amount</IonLabel>
                <IonInput value={loan_amount} onIonInput={handleLoanChange}  type="number" name="loan_amount" placeholder="Enter Loan Amount"></IonInput>
              </IonItem>
          </IonCol>

          <IonCol size="12" size-md="6" size-lg="4">
             <IonItem>
                <IonLabel position="floating">Duration</IonLabel>
                <IonInput value={duration} onIonInput={handleDurationChange}  type="number" name="duration" placeholder="Enter Duration"></IonInput>
              </IonItem>
          </IonCol>

          <IonCol size="12" size-md="6" size-lg="4">
              <IonItem>
                <IonLabel position="floating">Interest Rate</IonLabel>
                <IonInput value={interest} onIonInput={handleInterestChange}  type="number" name="loan_interest" placeholder="Enter Interest Rate"></IonInput>
              </IonItem>
          </IonCol>

          <IonCol size="12" size-md="6" size-lg="4">
            <br/>
            <IonButton size="default" onClick={handleClick}>Submit</IonButton>
          </IonCol>
          </IonRow>
        </IonGrid>

      <IonRow>
        <IonCol size="12" size-md="6" size-lg="12">
          <IonCard>
           {loan_report.length > 0 &&
              <div>
              <IonButton size="small" onClick={handlePrint}>Print</IonButton>
              <IonCardHeader id="printContent">
              
                <IonCardTitle>Name : {loan_report[0].name ? loan_report[0].name : ''}</IonCardTitle>
                <IonCardTitle>Aadhar Number : {loan_report[0].aadhar_no ? loan_report[0].aadhar_no : ''}</IonCardTitle>
                <IonCardTitle>Loan Category : {loan_report[0].category ? loan_report[0].category : ''}</IonCardTitle>
                <IonCardTitle>Interest Rate : {loan_report[0].interest ? loan_report[0].interest : ''} %</IonCardTitle>
                <IonCardTitle>Loan Duration : {loan_report[0].duration ? loan_report[0].duration : ''}</IonCardTitle>
                
                <IonCardTitle>Loan Amount : {loan_report[0].loan_amount ? loan_report[0].loan_amount : ''}</IonCardTitle>
                <IonCardTitle>Interest {loan_report[0].duration} {loan_report[0].type} : {loan_report[0].loan_interest_rate ? loan_report[0].loan_interest_rate : 0}</IonCardTitle>
                <IonCardTitle>Document Charge : {loan_report[0].docu_charge ? loan_report[0].docu_charge : 0}</IonCardTitle>
                <IonCardTitle>Collection Agent Fee : {loan_report[0].collection_agent ? loan_report[0].collection_agent : 0}</IonCardTitle>
                
            </IonCardHeader>
           </div>
           }
          </IonCard>
        </IonCol>
      </IonRow>
      </IonContent>
    </IonPage>
   </>
  );
};
export default LoanConfig;
