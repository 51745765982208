import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';
import CustomerLoanList from '../components/CustomerLoanList';
import Loan from '../components/Loan';
import User from '../components/User';
import Expense from '../components/Expense';
import LoanConfig from '../components/LoanConfig';
import ChangePassword from '../components/ChangePassword';
import LoanHistory from '../components/LoanHistory';
import Password from '../components/Password';
import LoanSearch from '../components/LoanSearch'
import About from '../components/About'
import Dashboard from '../components/Dashboard'
import './Page.css';


const Page: React.FC = (props) => {


  
  const { name } = useParams<{ name: string; }>();



  console.log(name)
  console.log("wewe")
  if(name == "Trash" || name == "logout"){
     localStorage.removeItem("user_id");
        localStorage.removeItem("user_role");
        localStorage.removeItem("name");
        localStorage.removeItem("token");
        window.location.href="/";
        // fetch(`http://localhost:8000/api/logout`,{
        // headers: {
        //   "Content-type": "application/json",
        //   "Authorization": "Bearer " + localStorage.getItem('token'),
        //   "Accept":"application/json"
        // }
        // }).then(response => response.json())
        // .then(json => {

        // });
  }


  if(name == "loan")
  {
    return <Loan {...props}/>
  }
  if(name == "user")
  {
    return <User {...props}/>
  }
  if(name == "loan_search"){
    return <LoanSearch {...props}/>
  }
  if(name == "expense")
  {
    return <Expense {...props}/>
  }
  if(name == "change_password")
  {
    return <ChangePassword {...props}/>
  }
  if(name == "loan_interest")
  {
    return <LoanConfig {...props}/>
  }
  if(name == "loan_list")
  {
    return <CustomerLoanList {...props}/>
  }
  if(name == "password")
  {
    return <Password {...props}/>
  }
  if(name=="loan_history"){
    return <LoanHistory {...props}/>
  }
  if(name== "dashboard"){
    return <Dashboard {...props}/>
  }
  else{
    return <About {...props}/>
  }
};
export default Page;
