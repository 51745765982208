import { IonCol,useIonLoading, IonGrid,useIonAlert, IonRow,IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar,IonItem,IonLabel,IonInput,IonNote,IonButton,IonList,IonSelect,IonSelectOption } from '@ionic/react';
import { useState, useEffect } from "react";
import ExpenseTable from './ExpenseTable';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
import DataTable from 'react-data-table-component';

const Expense: React.FC = (props)=>{
const [present, dismiss] = useIonLoading();
const [presentAlert] = useIonAlert();
const [show, setShow] = useState(false);
const [render, setComponent] = useState(true);
const [txn_type,setTxnType] = useState('')
const [amount,setAmount] = useState('')
const [description,setDescription] = useState('')
const [expense_details, setExpenseDetails] = useState([]);
const[filter_data,setFilterData]=useState([])
const [search,setSearch]=useState('')

const handleTxnChange = (e)=>{
 setTxnType(e.target.value)
}
const handleAmountChange = (e)=>{
 setAmount(e.target.value) 
}
const handleDescChange = (e)=>{
  setDescription(e.target.value)
}

useEffect(() => {
  setShow(false)
    fetch(`${props.api_url}/api/expense/show`,{
      headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token'),
        "Accept":"application/json"
      }
    }).then(response => response.json())
    .then(json => {
      if(json.status == 401){
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        window.location.href="/"
      }else{
       console.log(json.data)
       setExpenseDetails(json.data)
       setFilterData(json.data)
       setShow(true)
      }
    });
}, [expense_details]);


useEffect(()=>{
  console.log("search")
  console.log(search)
  if(search !="" && search !=null){
    const result=expense_details.filter(data=>{
        return data.txn_type.toLowerCase().match(search.toLowerCase()) || data.description.toLowerCase().match(search.toLowerCase())  
      })
    setFilterData(result)
  }else{
      setFilterData(expense_details)
  }  
},[search])


const columns=[
{
    name:"Txn Type",
    selector:(row)=>row.txn_type,
    sortable:true,
  },
  {
    name:"Amount",
    selector:(row)=>row.amount,
    sortable:true,
  },
  {
    name:"Description",
    selector:(row)=>row.description,
    sortable:true,
  },
  {
    name:"Created At",
    selector:(row)=>row.created_at,
    sortable:true,
  },
   {
    name:"Action",
    cell:(row)=><IonButton color="danger" size="small" onClick={e => handleDelete(row.id)}>Delete</IonButton>,
  },
]



const handleClick = ()=>{
   if(!txn_type){
     presentAlert({
        subHeader: 'Please choose the transaction type',
        buttons: ['OK'],
      }) 
    }else if(!amount){
     presentAlert({
        subHeader: 'Please enter the amount',
        buttons: ['OK'],
      }) 
    }else if(!description){
     presentAlert({
        subHeader: 'Please enter the description',
        buttons: ['OK'],
      }) 
    }else{
      present({
        message: 'Loading...',
        spinner: 'circles'
      });
        var data = {}
      data.txn_type = txn_type
      data.amount = amount
      data.description = description
      fetch(`${props.api_url}/api/expense/create`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token'),
        "Accept":"application/json"
      }
    }).then(response => response.json())
   .then(json =>{
       dismiss({
        message: 'Loading...',
        spinner: 'circles'
      });
  console.log("json")
 
   console.log("json")
setTxnType('')
setAmount('')
setDescription('')
  // setAllValues({txn_type: ' ', amount: ' ', description: ' '})
  if(json.status == 400){
      presentAlert({
        subHeader: json.message,
        buttons: ['OK'],
      }) 
  }else if(json.status == 401){
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    window.location.href="/"
  }

  else{
      setExpenseDetails(json.data)
      setComponent(true)
         
      presentAlert({
        subHeader: `Expense added Successfully`,
        buttons: ['OK'],
      }) 
  }
})
    }
}


 const handleDelete = (id)=>{
   presentAlert({
            header: 'Are you sure want to delete the expense',
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
              },
              {
                text: 'OK',
                role: 'confirm',
                handler: () => {
       present({
        message: 'Loading...',
        spinner: 'circles'
      });

      var deleteData = {}
      deleteData.id=id
      fetch(`${props.api_url}/api/expense/delete`, {
          method: "POST",
          body: JSON.stringify(deleteData),
        headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token'),
        "Accept":"application/json"
      }
        }).then(response => response.json())
        .then(json =>{
          dismiss({
            message: 'Loading...',
            spinner: 'circles'
          });

        if(json.status == 401){
          localStorage.removeItem('token');
          localStorage.removeItem('user_id');
          window.location.href="/"
        }else{
           setExpenseDetails(json.data)
        setComponent(true)
        presentAlert({
          subHeader: `Expense deleted Successfully`,
          buttons: ['OK'],
        })  
        }
      
        })   
                },
              },
            ],
           
          })
 }


  return (
   <>
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Expense</IonTitle>
        </IonToolbar>
        </IonHeader>
        <IonContent fullscreen className="main-content">
          <IonGrid>
          <IonRow>
          <IonCol size="12" size-md="6" size-lg="4">
            <IonList>
              <IonItem>
                <IonSelect value={txn_type} onIonChange={handleTxnChange} name="txn_type" placeholder="Select Transaction Type">
                  <IonSelectOption value="debit">Debit</IonSelectOption>
                  <IonSelectOption value="credit">Credit</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonList>
          </IonCol>
          <IonCol size="12" size-md="6" size-lg="4">
            <IonItem>
              <IonLabel position="floating">Amount</IonLabel>
              <IonInput value={amount} type="number" onIonInput={handleAmountChange} name="amount" placeholder="Enter Amount"></IonInput>
            </IonItem>
          </IonCol>
          <IonCol size="12" size-md="6" size-lg="4">
            <IonItem>
              <IonLabel position="floating">Description</IonLabel>
              <IonInput value={description} onIonInput={handleDescChange} name="description" placeholder="Enter Description"></IonInput>
            </IonItem>
          </IonCol>
          <IonCol size="12" size-md="6" size-lg="4">
            <IonButton size="default" onClick={handleClick}>Submit</IonButton>
          </IonCol>
          </IonRow>
          </IonGrid>
         <IonRow>
         <IonCol size="12" size-md="6" size-lg="12">
           <DataTable 
        columns={columns}
        data={filter_data}
        pagination
        fixedHeader
        subHeader
        subHeaderComponent={
          <input type="text" placeholder="Search here"
          className="w-25 form-control"
          value={search}
          onChange={(e)=>setSearch(e.target.value)}/>
         }
        />
         
         </IonCol>
          </IonRow>
        </IonContent>
    </IonPage>
   </>
  );
};
export default Expense;
