
import {  createAnimation,
  IonModal,
  IonAvatar,
  IonCard,
  IonBadge,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonImg,IonCol,useIonLoading, IonGrid,useIonAlert, IonRow,IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar,IonItem,IonLabel,IonInput,IonNote,IonButton,IonList,IonSelect,IonSelectOption } from '@ionic/react';

import { useState, useEffect } from "react";
import { IonReactRouter } from '@ionic/react-router';
import Menu from './Menu';
import LoanTables from './LoanTables';
import './Loan.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
import {printDocument} from "react-to-pdf-docs"

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import DataTable from 'react-data-table-component';
const Loan: React.FC = (props)=>{
const [present, dismiss] = useIonLoading();
const [show, setShow] = useState(false);
const [presentAlert] = useIonAlert();
const [role, setRole] = useState("");
const [loan_no, setLoanNo] = useState("");
const [loan,setLoan] = useState([])
const initialValues = {
   customer_id: '',
   loan_category: '',
   loan_amount: '',
   loan_duration: '',
   loan_issue_date: '',
   loan_no: ''
}

const [allValues, setAllValues] = useState(initialValues);
const [loandetails, setLoanDetails] = useState([]);
const [userdetails, setUserDetails] = useState([]);
const [isOpen, setIsOpen] = useState(false);
const [viewLoan, setViewLoan] = useState([]);
const[customer_id,setCustomerId] = useState('')

//Form State
const [dl_number,setDLNumber] = useState('')
const[loan_category,setLoanCategory] = useState('')
const[loan_amount,setLoanAmount] = useState('')
const[loan_duration,setLoanDuration] = useState('')
const[loan_issue_date,setLoanIssueDate] = useState('')
const[loan_number,setLoanNumber] = useState('')
const [search,setSearch]=useState('')
const[loan_filter,setLoanFilter] = useState([])

const[loan_history_flag,setLoanHistoryFlag]=useState(false)

const[loan_history,setLoanHistory] =useState([])


const handleCustomerChange = (e) => {
  setCustomerId(e.target.value)
}
const handleLoanCategoryChange = (e) => {
  setLoanCategory(e.target.value)
}
const handleLoanDurationChange = (e) => {
  setLoanDuration(e.target.value)
}
const handleLoanAmountChange = (e) => {
  setLoanAmount(e.target.value)
}
const handleLoanNumberChange = (e) => {
  setLoanNumber(e.target.value)
}
const handleLoanIssueChange = (e) => {
  setLoanIssueDate(e.target.value)
}
const handleSuggestion = (e,newInputValue)=>{
 setDLNumber(newInputValue)
}

const viewcolumns=[
{
    name:"DL No",
    selector:(row)=>row.user_loan_no,
    sortable:true,
  },
  {
    name:"Name",
    selector:(row)=>row.name,
    sortable:true,
  },
  {
    name:"Loan No",
    selector:(row)=>row.loan_no,
    sortable:true,
  },
  {
    name:"Category / Duration",
    selector:(row)=><span><span>{row.loan_category}</span><br/><span>{row.loan_duration}</span></span>,
    sortable:false,
  },
  {
    name:"Due Date",
    selector:(row)=>row.loan_issue_date,
    sortable:true,
  },
  {
    name:"Due Amount",
    selector:(row)=>row.due_amount,
    sortable:true,
  },
  {
    name:"Paid Amount",
    selector:(row)=>row.paid_amount,
    sortable:true,
  },
  {
    name:"Balance Amount",
    selector:(row)=>row.balance_amount,
    sortable:true,
  },
 
  {
    name:"Loan Status",
    cell:(row)=>row.status == "paid" ? <span className="HistorySec"><IonButton  color="success" size="small">Paid</IonButton><br/><IonButton color="tertiary" size="small" onClick={e => handleViewLoanHistory(row.customer_id,row.id,row.loan_no)}>View History</IonButton></span> : <span className="HistorySec"><IonButton size="small">{row.status}</IonButton><br/><IonButton onClick={e => handleViewLoanHistory(row.customer_id,row.id,row.loan_no)} color="tertiary" size="small">View History</IonButton></span>,
    sortable:false,
  },
]



const viewhistorycolumns=[
{
    name:"DL No",
    selector:(row)=>row.user_loan_no,
    sortable:true,
  },
  {
    name:"Name",
    selector:(row)=>row.name,
    sortable:true,
  },
  {
    name:"Loan No",
    selector:(row)=>row.loan_no,
    sortable:true,
  },
  
  {
    name:"Paid Date",
    selector:(row)=>row.loan_paid_date,
    sortable:true,
  },
  {
    name:"Paid Amount",
    selector:(row)=>row.loan_paid_amount,
    sortable:true,
  },
  {
    name:"Balance Amount",
    selector:(row)=>row.loan_balanc_amount,
    sortable:true,
  },
 
  {
    name:"Loan Status",
    cell:(row)=>row.status == "paid" ? <span className="HistorySec"><IonButton  color="success" size="small">Paid</IonButton></span> : <span className="HistorySec"><IonButton size="small">{row.status}</IonButton><br/></span>,
    sortable:false,
  },
]



const handleViewLoanHistory = (customer_id,loan_id,loan_no)=>{
  setIsOpen(false)
  setLoanHistoryFlag(true)
          present({
        message: 'Loading...',
        spinner: 'circles'
      });
        var data = {}
        data.customer_id =customer_id
        data.loan_id = loan_id
        data.loan_no = loan_no
        fetch(`${props.api_url}/api/loan/loan_history`, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token'),
            "Accept":"application/json"
          }
        }).then(response => response.json())
        .then(json =>{
            dismiss({
              message: 'Loading...',
              spinner: 'circles'
            });
             if(json.status == 400){
              presentAlert({
                subHeader: json.message,
                buttons: ['OK'],
              }) 
            }else if(json.status == 401){
                localStorage.removeItem('token');
                localStorage.removeItem('user_id');
                window.location.href="/"
            }else{
               console.log("TEST")
               console.log(json.data)
               setLoanHistory(json.data)
            }
        })
    }

const columns=[
{
    name:"DL No",
    selector:(row)=>row.user_loan_no,
    sortable:true,
  },
  {
    name:"Name / Mobile",
    selector:(row)=><span className="passwordSec">
      <span>{row.name}</span><br/>
      <span>{row.mobile}</span>
    </span>,
    sortable:false,
  },
  {
    name:"Loan No",
    selector:(row)=>row.loan_no,
    sortable:true,
  },
  {
    name:"Category",
    selector:(row)=>row.loan_category,
    sortable:true,
  },
  {
    name:"Duration",
    selector:(row)=>row.loan_duration,
    sortable:true,
  },
  {
    name:"Total Loan Amount",
    selector:(row)=>row.loan_amount,
    sortable:true,
  },
   {
    name:"Total Paid Amount",
    selector:(row)=>row.paid_amount,
    sortable:true,
  },
  {
    name:"Loan Status",
    cell:(row)=>row.loan_status == "open" ? <IonButton color="medium" size="small">Open</IonButton> : <IonButton color="success" size="small">Closed</IonButton>,
    sortable:false,
  },
  {
    name:"Action",
    cell:(row)=><span><IonButton color="danger" size="small" onClick={e => handleDelete(row.loan_no)}>Delete</IonButton>
              <IonButton size="small" onClick={e => handleViewLoan(row.loan_no)}>View</IonButton></span>,
  },
]




useEffect(() => {
    fetch(`${props.api_url}/api/user_loan`,{
      headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token'),
        "Accept":"application/json"
      }
    }).then(response => response.json())
    .then(json => {
      if(json.status == 401){
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        window.location.href="/"
      }else{
        setLoan(json.data) 

      }

    });

    fetch(`${props.api_url}/api/user_details`,{
       headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token'),
            "Accept":"application/json"
          }
    }).then(response => response.json())
    .then(json => {
      if(json.status == 401){
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        window.location.href="/"
      }else{
         console.log(json.data)
       setUserDetails(json.data)
      }
    });

    fetch(`${props.api_url}/api/loan/show`,{
       headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token'),
            "Accept":"application/json"
          }
    }).then(response => response.json())
    .then(json => {
      if(json.status == 401){
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        window.location.href="/"
      }else{
         console.log(json.data)
       setLoanDetails(json.data)
       setShow(true)
        setLoanFilter(json.data) 
      }
    });
    setRole(localStorage.getItem('user_role'))
}, [loandetails]);



useEffect(()=>{
  console.log("search")
  console.log(search)
  if(search !="" && search !=null){
    const result=loandetails.filter(data=>{
        return data.name.toLowerCase().match(search.toLowerCase()) || data.mobile.toLowerCase().match(search.toLowerCase())|| data.user_loan_no.toLowerCase().match(search.toLowerCase())
        || data.loan_no.toLowerCase().match(search.toLowerCase())  
      })
    setLoanFilter(result)
  }else{
      setLoanFilter(loandetails)
  }  
},[search])


//handle Click
const handleClick = ()=>{
    if(!dl_number){
     presentAlert({
        subHeader: 'Please choose the DL Number',
        buttons: ['OK'],
      }) 
    }else if(!loan_category){
     presentAlert({
        subHeader: 'Please choose the loan category',
        buttons: ['OK'],
      }) 
    }else if(!loan_amount){
     presentAlert({
      subHeader: 'Please enter the loan amount',
      buttons: ['OK'],
      }) 
    }else if(!loan_duration){
     presentAlert({
      subHeader: 'Please enter the loan duration',
      buttons: ['OK'],
      }) 
    }else if(!loan_number){
     presentAlert({
      subHeader: 'Please enter the loan number',
      buttons: ['OK'],
      }) 
    }else if(!loan_issue_date){
     presentAlert({
      subHeader: 'Please choose the loan issue date',
      buttons: ['OK'],
      }) 
    }else{
        present({
        message: 'Loading...',
        spinner: 'circles'
      });
        var data = {}
        data.dl_number =dl_number
        data.loan_category = loan_category
        data.loan_amount = loan_amount
        data.loan_duration = loan_duration
        data.loan_issue_date = loan_issue_date
        data.loan_no = loan_number
        data.action = "add"
        fetch(`${props.api_url}/api/loan/create`, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token'),
            "Accept":"application/json"
          }
        }).then(response => response.json())
        .then(json =>{
            dismiss({
              message: 'Loading...',
              spinner: 'circles'
            });
            setDLNumber('')
            setLoanCategory('')
            setLoanAmount('')
            setLoanDuration('')
            setLoanIssueDate('')
            setLoanNumber('')
            if(json.status == 400){
              presentAlert({
                subHeader: json.message,
                buttons: ['OK'],
              }) 
            }else if(json.status == 401){
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
             window.location.href="/"
      }else{
              setLoanDetails(json.data)
              presentAlert({
                subHeader: `Loan added Successfully`,
                buttons: ['OK'],
              }) 
            }
        })
    }
  }
  //handle delete
const handleDelete = (loan_no)=>{
    presentAlert({
          header: 'Are you sure want to delete the loan',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
            },
            {
              text: 'OK',
              role: 'confirm',
              handler: () => {
      var deleteData = {}
    deleteData.loan_no=loan_no
     present({
      message: 'Loading...',
      spinner: 'circles'
    });

    fetch(`${props.api_url}/api/loan/deleteloan`, {
        method: "POST",
        body: JSON.stringify(deleteData),
       headers: {
          "Content-type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem('token'),
          "Accept":"application/json"
        }
      }).then(response => response.json())
      .then(json =>{
        dismiss({
          message: 'Loading...',
          spinner: 'circles'
        });
      if(json.status == 401){
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        window.location.href="/"
      }else{
        setLoanDetails(json.data) 
        setUserDetails(json.data)
        presentAlert({
        subHeader: `Loan deleted Successfully`,
        buttons: ['OK'],
        }) 
      }
      })   
              },
            },
      ],
         
      })
}

const handleClose=()=>{
  setLoanHistoryFlag(false)
  setIsOpen(true)
}
 //handle view loan
 const handleViewLoan= (loan_no)=>{
   present({
        message: 'Loading...',
        spinner: 'circles'
      });
  setIsOpen(true)
  setLoanNo(loan_no)
  var data={}
  data.loan_no = loan_no
  fetch(`${props.api_url}/api/loan/viewLoan`, {
      method: "POST",
      body: JSON.stringify(data),
       headers: {
          "Content-type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem('token'),
          "Accept":"application/json"
        }
    }).then(response => response.json())
   .then(json =>{
     dismiss({
        message: 'Loading...',
        spinner: 'circles'
      });
     if(json.status == 401){
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        window.location.href="/"
      }else{
        console.log("json")
      console.log(json)
      setViewLoan(json.data)
      }
      

   })
 }
  return (
   <>
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Loan</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="main-content">
        <IonGrid>
          <IonRow>
            <IonCol size="12" size-md="6" size-lg="4">
              <Stack spacing={1} sx={{ width: 300 }}>
              <Autocomplete
              id="free-solo-demo"
              freeSolo
              value={dl_number}
              onChange={handleSuggestion}
              options={loan.map((option) => option.user_loan_no)}
              renderInput={(params) => <TextField {...params} label="DL Number" />}
              />
              </Stack>
            </IonCol>
            <IonCol size="12" size-md="6" size-lg="4">
              <IonList>
                <IonItem>
                  <IonSelect value={loan_category} onIonChange={handleLoanCategoryChange} name="loan_category" placeholder="Select Loan Category">
                    <IonSelectOption value="daily">Daily</IonSelectOption>
                    <IonSelectOption value="monthly">Monthly</IonSelectOption>
                    <IonSelectOption value="weekly">Weekly</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonList>
            </IonCol>
            <IonCol size="12" size-md="6" size-lg="4">
              <IonItem>
                <IonLabel position="floating">Loan Amount</IonLabel>
                <IonInput value={loan_amount} type="number" 
                name="loan_amount" onIonInput={handleLoanAmountChange} placeholder="Enter Loan Amount"></IonInput>
              </IonItem>
            </IonCol>
            <IonCol size="12" size-md="6" size-lg="4">
              <IonItem>
                <IonLabel position="floating">Loan Duration</IonLabel>
                <IonInput value={loan_duration} type="number" onIonInput={handleLoanDurationChange} name="loan_duration" placeholder="Enter Loan Duration"></IonInput>
              </IonItem>
            </IonCol>

             <IonCol size="12" size-md="6" size-lg="4">
              <IonItem>
                <IonLabel position="floating">Loan Number</IonLabel>
                <IonInput value={loan_number} type="text" onIonInput={handleLoanNumberChange} name="loan_no" placeholder="Enter Loan Number"></IonInput>
              </IonItem>
            </IonCol>

            <IonCol size="12" size-md="6" size-lg="4">
              <IonItem>
                <IonLabel position="floating">Loan Issue Date</IonLabel>
                <IonInput value={loan_issue_date} name="loan_issue_date" type="date" onIonInput={handleLoanIssueChange} placeholder="Enter Loan Duration"></IonInput>
              </IonItem>
            </IonCol>
            <IonCol size="12" size-md="6" size-lg="4">
              <br/>
              <IonButton size="default" onClick={handleClick}>Submit</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>

 <IonRow>
 <IonCol size="12" size-md="6" size-lg="12">
     <DataTable 
        columns={columns}
        data={loan_filter}
        pagination
        fixedHeader
        subHeader
        subHeaderComponent={
          <input type="text" placeholder="Search here"
          className="w-25 form-control"
          value={search}
          onChange={(e)=>setSearch(e.target.value)}/>
        }
        />

 </IonCol>
 </IonRow>




 <div id="box">
        <IonModal isOpen={isOpen} >
          <IonHeader>
            <IonToolbar>
              <IonTitle>Loan Details (Loan No : {loan_no})</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setIsOpen(false)}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
         
            <IonCol size="12" size-md="6" size-lg="4">
            <IonCard>
            <IonCardHeader>
              <IonRow>
                <IonCol size="12" size-md="6" size-lg="12">
                
                       <DataTable 
        columns={viewcolumns}
        data={viewLoan}
        pagination
       
        />

                </IonCol>
              </IonRow>

            
            </IonCardHeader>
          <IonCardContent>
          <IonRow>
          </IonRow>
          </IonCardContent>
          </IonCard>
            </IonCol>
          </IonContent>
        </IonModal>
        </div>





        <div id="box">
        <IonModal isOpen={loan_history_flag} >
          <IonHeader>
            <IonToolbar>
            <IonTitle>Loan History</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => handleClose()}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
         
            <IonCol size="12" size-md="6" size-lg="4">
            <IonCard>
            <IonCardHeader>
              <IonRow>
                <IonCol size="12" size-md="6" size-lg="12">
                 
                     <DataTable 
        columns={viewhistorycolumns}
        data={loan_history}
        pagination
       
        /> 

                </IonCol>
              </IonRow>

            
            </IonCardHeader>
          <IonCardContent>
          <IonRow>
          </IonRow>
          </IonCardContent>
          </IonCard>
            </IonCol>
          </IonContent>
        </IonModal>
        </div>
  </IonContent>
</IonPage> 
         
    
  
  
   </>
  );
};
export default Loan;
