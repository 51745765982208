import { IonApp,useIonRouter, IonRouterOutlet, IonSplitPane, setupIonicReact,useIonLoading,IonButton  } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route,useLocation,useRouteMatch,BrowserRouter } from 'react-router-dom';

import Menu from './components/Menu';
import Loan from './components/Loan';
import User from './components/User';

import Customer from './pages/Customer';
import Page from './pages/Page';
import Login from './pages/Login';
import { useState, useEffect } from "react";
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

setupIonicReact();

const App: React.FC = () => {
const [present, dismiss] = useIonLoading();
const [show, setShow] = useState(false);
const [login,setLogin]= useState('')
const [role,setRole]= useState(null)
const router = useIonRouter();

const api_url = "https://eeswari-api.wajehalkhairtrading.com";

useEffect(() => {
   console.log("USE EFFECT")
   setLogin(localStorage.getItem('token'))
   setRole(localStorage.getItem('user_role'))
   console.log(localStorage.getItem('token'))
      localStorage.getItem('token');
      localStorage.getItem('user_id');
      localStorage.getItem('user_role');

 }, []);
 
  if(login == null){
    return <Login api_url={api_url}/>
  } 
  if(role == "admin" || role == "super_admin" ){
     return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu api_url={api_url}/>
          <IonRouterOutlet id="main">
            <Route path="/" exact={true}>
              <Page role={role} api_url={api_url}/>
            </Route>
            <Route path="/page/:name" exact={true}>
              <Page role={role} api_url={api_url}/>
            </Route>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
    
  );
  }else if(role == "customer"){
     return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu api_url={api_url}/>
          <IonRouterOutlet id="main">
            <Route path="/" exact={true}>
              <Page role={role} api_url={api_url}/>
            </Route>
             <Route path="/customer/:name" exact={true}>
              <Page role={role} api_url={api_url}/>
            </Route>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
    
  );
  }
};

export default App;
