import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
import { archiveOutline, archiveSharp, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import './Menu.css';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
{
    title: 'Dashboard',
    url: '/page/dashboard',
    iosIcon: archiveOutline,
    mdIcon: archiveSharp
  },
  {
    title: 'User',
    url: '/page/user',
    iosIcon: archiveOutline,
    mdIcon: archiveSharp
  },
  {
    title: 'Loan',
    url: '/page/loan',
    iosIcon: mailOutline,
    mdIcon: mailSharp
  },
 
  {
    title: 'Expense',
    url: '/page/expense',
    iosIcon: paperPlaneOutline,
    mdIcon: paperPlaneSharp
  },
  
  {
    title: 'Loan Interest',
    url: '/page/loan_interest',
    iosIcon: heartOutline,
    mdIcon: heartSharp
  },
   {
    title: 'Loan search',
    url: '/page/loan_search',
    iosIcon: heartOutline,
    mdIcon: heartSharp
  },
  {
    title: 'Logout',
    url: '/page/Trash',
    iosIcon: trashOutline,
    mdIcon: trashSharp
  }
];

const labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

const Menu: React.FC = (props) => {
  const location = useLocation();

  return (
    
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>Eeswaree finance</IonListHeader>
        
             <IonNote>Hi {localStorage.getItem('name') ? localStorage.getItem('name') : 'Customer'}</IonNote>
        
         
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
