import { IonCol, useIonLoading,IonAvatar ,IonGrid, useIonAlert ,IonRow,IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar,IonItem,IonLabel,IonInput,IonNote,IonButton,IonList,IonSelect,IonSelectOption } from '@ionic/react';
import { useState, useEffect } from "react";
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import Loan from '../components/Loan';
import Customer from '../components/Customer';
import './Login.css';
import CustomerLoanList from '../components/CustomerLoanList';
const Login = (props) => {
const [present, dismiss] = useIonLoading();
const [adminshow, setAminShow] = useState(false);
const [clientshow, setClientShow] = useState(false);
const [presentAlert] = useIonAlert();
const initialValues = {
   mobile: '',
   password: ''
}

const [allValues, setAllValues] = useState(initialValues);
const handleChange = (event)=> {
  console.log(event.target.value)
   setAllValues({...allValues, [event.target.name]: event.target.value})
}
const handleClick = ()=>{
  
    if(!allValues.mobile){
     presentAlert({
        subHeader: 'Please enter the mobile number',
        buttons: ['OK'],
      }) 
    }else if(!allValues.password){
     presentAlert({
        subHeader: 'Please enter the password',
        buttons: ['OK'],
      }) 
    }else{
     present({
        message: 'Loading...',
        spinner: 'circles'
    });
      var data = {}
      data.mobile = allValues.mobile
      data.password = allValues.password
      fetch(`${props.api_url}/api/login`, {
      method: "POST",
      crossDomain:true,
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    }).then(response => response.json())
   .then(json =>{

  if(json.status == 400){
      presentAlert({
        subHeader: json.message,
        buttons: ['OK'],
      }) 
  }else{
    if(json.status == 200){
      console.log(json.data.token)
      console.log(json.data.user_id)
      console.log(json.data.user_role)
      console.log(json.data.name)

      localStorage.setItem('token',json.data.token);
      localStorage.setItem('user_id',json.data.user_id);
      localStorage.setItem('user_role',json.data.user_role);
      localStorage.setItem('name',json.data.name);
      if(json.data.user_role !="customer"){
        setAminShow(true)
      }else{
        setClientShow(true)
      }
      
    } 
  }
   dismiss({
        message: 'Loading...',
        spinner: 'circles'
      });
})
    }
  }
 if (adminshow) {
    window.location.href="/page/dashboard"
  }else if(clientshow){
    window.location.href="/customer/loan_list"
  }
  else {
    return (
    <div className="loginImage">
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Login</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="login-page-content">
        <IonGrid>
          <IonRow className="loginSec">
         
            <IonCol size="12" size-md="6" size-lg="4">
              <IonItem>
                <IonLabel position="floating">Mobile Number</IonLabel>
                <IonInput type="text" onIonInput={handleChange} name="mobile" placeholder="Enter Mobile Number"></IonInput>
              </IonItem>
            </IonCol>
            <IonCol size="12" size-md="6" size-lg="4">
              <IonItem>
                <IonLabel position="floating">Password</IonLabel>
                <IonInput type="password" onIonInput={handleChange} name="password" placeholder="Enter Password"></IonInput>
              </IonItem>
            </IonCol>
            <IonCol size="12" size-md="6" size-lg="4">
              

              <IonButton className="btnColor" color="green" expand="full" onClick={handleClick}>Login</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
   </div>
  );
}
};
export default Login;
