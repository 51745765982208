import {  createAnimation,
  IonModal,
  IonAvatar,
  IonCard,
  IonBadge,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonImg,IonCol,useIonLoading, IonGrid,useIonAlert, IonRow,IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar,IonItem,IonLabel,IonInput,IonNote,IonButton,IonList,IonSelect,IonSelectOption } from '@ionic/react';
import { useState, useEffect,useRef  } from "react";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';



const LoanSearch: React.FC = (props)=>{
  const initialValues = {
   txn_amount: ''
}
const [present, dismiss] = useIonLoading();
const [isOpen, setIsOpen] = useState(false);
const [presentAlert] = useIonAlert();
const [show, setShow] = useState(false);
const [txn_amount, setAllValues] = useState('');
const [expense_details, setExpenseDetails] = useState([]);
const [customer_loan,setCustomerLoan] = useState([]);
const [loan, setLoan] = useState([]);

const [loan_amount,setLoanAmount] =useState('')
const [due_amount,setDueAmount] =useState('')
const [balanc_amount,setBalancAmount] =useState('')
const [paid_amount,setPaidAmount] =useState('')
const [user_loan_no,setUserLoanNo] = useState('')

const handleChange = (e) => {
   setAllValues(e.target.value)
}



const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '12 Angry Men', year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: 'Pulp Fiction', year: 1994 }];
const handleClick = ()=>{
   if(!txn_amount){
     presentAlert({
        subHeader: 'Please enter the loan amount',
        buttons: ['OK'],
      }) 
    }else{
        present({
        message: 'Loading...',
        spinner: 'circles'
      });
      var data = {}
      data.txn_amount = txn_amount
      data.loan_no = user_loan_no
      fetch(`${props.api_url}/api/loan/paid_loan`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token'),
        "Accept":"application/json"
      }
    }).then(response => response.json())
   .then(json =>{
      setAllValues('')
      dismiss({
        message: 'Loading...',
        spinner: 'circles'
      });
  console.log("json")
  console.log(json)
  if(json.status == 400){
      presentAlert({
        subHeader: json.message,
        buttons: ['OK'],
      }) 
  }else if(json.status == 401){
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    window.location.href="/"
  }
  else{
      presentAlert({
        subHeader: `loan amount added Successfully`,
        buttons: ['OK'],
      }) 
      setIsOpen(false)

  }
})
    }
}



useEffect(() => {
    console.log('trigger use effect hook');

     
    fetch(`${props.api_url}/api/user_loan`,{
      headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token'),
        "Accept":"application/json"
      }
    })
    // Converting received data to JSON
    .then(response => response.json())
    .then(json => {
      if(json.status == 401){
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        window.location.href="/"
      }else{
        console.log(json.data)
       setLoan(json.data)
      }
    });
}, []);



const handleCardClick = (loan_no,loan_amount,due_amount,paid_amount,balancAmt)=>{
       setDueAmount('')
  setBalancAmount('') 
  setPaidAmount('')

      present({
        message: 'Loading...',
        spinner: 'circles'
      });
      setIsOpen(true)
      setUserLoanNo(loan_no)
      var data = {}
      data.loan_no = loan_no
      fetch(`${props.api_url}/api/loan/separateLoan`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token'),
        "Accept":"application/json"
      }
    }).then(response => response.json())
   .then(json =>{
      dismiss({
        message: 'Loading...',
        spinner: 'circles'
      });
  console.log("json")
  console.log(json)
  if(json.status == 400){
      presentAlert({
        subHeader: json.message,
        buttons: ['OK'],
      }) 
  }else if(json.status == 401){
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    window.location.href="/"
  }
  else{
    console.log("DATA")
    console.log(json.data)
    console.log(json.data[0])
  var resp = json.data[0]
  setLoanAmount(resp.loan_amount)
  setDueAmount(resp.due_amount)
  const balanceAmounts = resp.due_amount - resp.paid_amount
  const paidAmount = balanceAmounts - resp.balance_amount
  setBalancAmount(balanceAmounts) 
  setPaidAmount(resp.paid_amount)
  }
})  
}
const handleSuggestion = (e,newInputValue)=>{
  
  if(newInputValue !=""){
    setCustomerLoan([])
    present({
        message: 'Loading...',
        spinner: 'circles'
      });
      var data = {}
      data.user_loan_no = newInputValue
      fetch(`${props.api_url}/api/loan/loan_detail`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token'),
        "Accept":"application/json"
      }
    }).then(response => response.json())
   .then(json =>{
  console.log("json")
  console.log(json)
  dismiss({
        message: 'Loading...',
        spinner: 'circles'
      });
  if(json.status == 400){
      presentAlert({
        subHeader: json.message,
        buttons: ['OK'],
      }) 
  }else if(json.status == 401){
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    window.location.href="/"
  }
  else{
    console.log("DATA")
     console.log(json.data)
     setCustomerLoan(json.data)
  }
})  
  }
}

  return (
   <>
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Payment Details</IonTitle>
        </IonToolbar>
        </IonHeader>
        <IonContent fullscreen className="main-content">
          <IonGrid>
          <IonRow>
          <IonCol size="12" size-md="6" size-lg="4">
            <Stack spacing={1} sx={{ width: 300 }}>
      <Autocomplete
        id="free-solo-demo"
        freeSolo
        onChange={handleSuggestion}
        options={loan.map((option) => option.user_loan_no)}
        renderInput={(params) => <TextField {...params} label="DL Number" />}
      />
      </Stack>
     </IonCol>  
    </IonRow>

     <IonRow>
        {customer_loan.length > 0 ?
          customer_loan.map((loan) => (
        <IonCol size="12" size-md="6" size-lg="6">
          <IonCard onClick={e=>handleCardClick(loan.loan_no,loan.loan_amount,loan.due_amount,loan.paid_amount,loan.balance_amount)}>
          <IonCardHeader>
            <IonCardTitle>Loan No  : {loan.loan_no}</IonCardTitle>
            <IonCardTitle>Total Loan Amount  : {loan.loan_amount ? Math.round(loan.loan_amount) : 0}</IonCardTitle>
            <IonCardTitle>Interest Rate  : {Math.round(loan.loan_interest_amount)} %</IonCardTitle>

            <IonCardTitle>Loan Category  : {loan.loan_category}</IonCardTitle>

         

             <IonCardTitle>Loan Duration  : {loan.loan_duration}</IonCardTitle>

            <IonCardTitle>Total Due Amount  : {loan.due_amount ? Math.round(loan.due_amount) : 0}</IonCardTitle>
            <IonCardTitle>Loan Status  : 
            {loan.loan_status == "open" ?
                <IonButton size="small" color="danger">Open</IonButton>
          
            :
                <IonButton size="small" color="success">Closed</IonButton>
            }
            </IonCardTitle>
            <IonRow>
            </IonRow>
          </IonCardHeader>
          </IonCard>
        </IonCol>
      ))
    :
    <IonRow>
      <IonCol size="12" size-md="12" size-lg="12">
        <IonCard>
        <IonCardHeader>
          <IonCardTitle>No Loan Found</IonCardTitle>
          </IonCardHeader>
        </IonCard>
      </IonCol>
    </IonRow>
    }
    
     </IonRow>
    </IonGrid>

     


 
        <IonModal isOpen={isOpen}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Payment Details (Loan No : {user_loan_no})</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setIsOpen(false)}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonCol size="12" size-md="6" size-lg="4">

    <IonCard>
<IonCardHeader>
<IonRow>
            <IonCol size="12" size-md="6" size-lg="6">
                <IonCardTitle>Total Due Amount : <IonBadge color="secondary">{due_amount ? Math.round(due_amount) : 0}</IonBadge></IonCardTitle>
            </IonCol>

            <IonCol size="12" size-md="6" size-lg="6">
                <IonCardTitle>Total Paid Amount : <IonBadge color="success">{paid_amount ? Math.round(paid_amount) : 0}</IonBadge></IonCardTitle>
            </IonCol>

            <IonCol size="12" size-md="6" size-lg="6">
                <IonCardTitle>Total Balance Amount : <IonBadge color="danger">{balanc_amount ? Math.round(balanc_amount) : 0}</IonBadge></IonCardTitle>
            </IonCol>
            </IonRow>

</IonCardHeader>

    <IonCardContent>
    <IonRow>
          <IonCol size="12" size-md="6" size-lg="6">
            <IonItem>
              <IonLabel position="floating">Loan Amount</IonLabel>
              <IonInput type="number" onIonInput={handleChange} name="txn_amount" placeholder="Enter Loan Amount"></IonInput>
            </IonItem>
          </IonCol>
          </IonRow>

          <IonRow>
          <IonCol size="6" size-md="6" size-lg="4">
              <br/>
            <IonButton size="default" onClick={handleClick}>Submit</IonButton>
          </IonCol>
          <IonCol size="6" size-md="6" size-lg="4">
              <br/>
          <IonButton color="medium" size="default" onClick={() => setIsOpen(false)}>Close</IonButton>
          </IonCol>
          </IonRow>
    </IonCardContent>
</IonCard>


             
            </IonCol>
          </IonContent>
        </IonModal>
   
    </IonContent>
    </IonPage>
   </>
  );
};
export default LoanSearch;
